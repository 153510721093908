import moment from 'moment';
import { Badge } from 'reactstrap';

import {
  USER_ROLE,
  ADMIN_ROLE,
  ACTIVE_USER_STATUS,
  CANCEL_ORDER_STATUS,
  LOCKED_ORDER_STATUS,
  PENDING_USER_STATUS,
  BLOCKED_USER_STATUS,
  USER_DASHBOARD_ROUTE,
  INACTIVE_USER_STATUS,
  PENDING_ORDER_STATUS,
  ACCEPTED_ORDER_STATUS,
  ADMIN_DASHBOARD_ROUTE,
  REJECTED_ORDER_STATUS,
  CLOSED_WISHLIST_STATUS,
  PENDING_WISHLIST_STATUS,
  NEW_POTENTIAL_BUY_STATUS,
  COMPLETED_WISHLIST_STATUS,
  PENDING_POTENTIAL_BUY_STATUS,
  REJECTED_POTENTIAL_BUY_STATUS,
  ACCEPTED_POTENTIAL_BUY_STATUS,
  CANCELLED_POTENTIAL_BUY_STATUS,
} from './constants';

const setUserInitialValues = (initalValues, user) => {
  return {
    ...initalValues,
    lastName: user?.lastName,
    firstName: user?.firstName,
  };
};

const getInitialDashboardRoute = (role) => {
  if (role === ADMIN_ROLE) {
    return ADMIN_DASHBOARD_ROUTE;
  } if (role === USER_ROLE) {
    return USER_DASHBOARD_ROUTE;
  }
};

const parseDate = (date) => {
  return moment(date).format('YYYY-MM-DD');
};

const parseDateString = (date) => {
  return moment(date).format('D MMM, YYYY h:mm A');
};

const parseTime = (time) => {
  return moment(time, 'HH:mm').format('h:mm A');
};

const createFormData = (data) => {
  const formData = new FormData();

  for (const key in data) {
    if (data.hasOwnProperty(key) && data[key] !== '' && data[key] !== null) {
      if (Array.isArray(data[key]) && data[key].length > 0) {
        if (key === 'serviceInterested') {
          const updatedValue = data[key].map((service) => {
            return service.value;
          });
          formData.append(key, JSON.stringify(updatedValue));
        } else {
          formData.append(key, JSON.stringify(data[key]));
        }
      } else if (!Array.isArray(data[key])) {
        formData.append(key, data[key]);
      }
    }
  }

  return formData;
};

const isAdmin = (role) => {
  return role === ADMIN_ROLE;
};

const isUser = (role) => {
  return role === USER_ROLE;
};

const showStatusBadge = (status) => {
  switch (status) {
  case ACTIVE_USER_STATUS:
    return (
      <Badge color="success" pill>
        Active
      </Badge>
    );
  case INACTIVE_USER_STATUS:
    return (
      <Badge color="warning" pill>
        InActive
      </Badge>
    );
  case PENDING_USER_STATUS:
    return (
      <Badge color="info" pill>
        Inomplete Profile
      </Badge>
    );
  case BLOCKED_USER_STATUS:
    return (
      <Badge color="danger" pill>
        Blocked
      </Badge>
    );

  default:
    break;
  }
};

const showPotentialBuyStatusBadge = (status) => {
  switch (status) {
  case NEW_POTENTIAL_BUY_STATUS:
    return (
      <Badge color="warning" pill>
        New
      </Badge>
    );
  case REJECTED_POTENTIAL_BUY_STATUS:
    return (
      <Badge color="danger" pill>
        Rejected
      </Badge>
    );
  case PENDING_POTENTIAL_BUY_STATUS:
    return (
      <Badge color="info" pill>
        Pending
      </Badge>
    );
  case CANCELLED_POTENTIAL_BUY_STATUS:
    return (
      <Badge color="danger" pill>
        Cancelled
      </Badge>
    );
  case ACCEPTED_POTENTIAL_BUY_STATUS:
    return (
      <Badge color="success" pill>
        Accepted
      </Badge>
    );
  default:
    break;
  }
};

const showOrderStatusBadge = (status) => {
  switch (status) {
  case PENDING_ORDER_STATUS:
    return (
      <Badge color="warning" pill>
        Pending
      </Badge>
    );
  case ACCEPTED_ORDER_STATUS:
    return (
      <Badge color="success" pill>
        Accepted
      </Badge>
    );
  case LOCKED_ORDER_STATUS:
    return (
      <Badge color="info" pill>
        Locked
      </Badge>
    );
  case CANCEL_ORDER_STATUS:
    return (
      <Badge color="danger" pill>
        Cancelled
      </Badge>
    );
  case REJECTED_ORDER_STATUS:
    return (
      <Badge color="danger" pill>
        Rejected
      </Badge>
    );

  default:
    break;
  }
};

const showWishlistBadge = (status) => {
  switch (status) {
  case COMPLETED_WISHLIST_STATUS:
    return (
      <Badge color="success" pill>
        Completed
      </Badge>
    );
  case CLOSED_WISHLIST_STATUS:
    return (
      <Badge color="danger" pill>
        Closed
      </Badge>
    );
  case PENDING_WISHLIST_STATUS:
    return (
      <Badge color="warning" pill>
        Pending
      </Badge>
    );

  default:
    break;
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  isUser,
  isAdmin,
  parseDate,
  parseTime,
  createFormData,
  showStatusBadge,
  parseDateString,
  showWishlistBadge,
  setUserInitialValues,
  showOrderStatusBadge,
  getInitialDashboardRoute,
  showPotentialBuyStatusBadge,
};
