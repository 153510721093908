import { thunk } from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import {
  configureStore,
  combineReducers,
  applyMiddleware,
} from '@reduxjs/toolkit';

import userSlice from './features/User/userSlice.js';
import buyerSlice from './features/Buyer/buyerSlice.js';
import adminSlice from './features/Admin/adminSlice.js';
import sellerSlice from './features/Seller/sellerSlice.js';

const persistConfig = {
  key: 'root',
  storage,
};

const rootReducer = combineReducers({
  user: userSlice,
  buyer: buyerSlice,
  seller: sellerSlice,
  admin: adminSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore(
  { reducer: persistedReducer },
  applyMiddleware(thunk),
);

export const persistor = persistStore(store);
