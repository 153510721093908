// @ts-nocheck
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

import { UserRole, UserStatus } from '../entities/User';
import {
  getRouteAddPharmacy,
  getRouteLogin,
  getRouteNoAccess,
  getRouteUserChangePassword,
  getRouteUserProfile,
} from '../Shared/const/router';

export function PrivateRoute({ Component, props }) {
  const { pathname } = useLocation();
  const { user } = useSelector((state) => state.user);

  if (!user) {
    return <Navigate to={getRouteLogin()} />;
  }

  if (user.role !== UserRole.ADMIN && user.status !== UserStatus.ACTIVE) {
    if (![getRouteNoAccess(), getRouteUserProfile(), getRouteUserChangePassword(), getRouteAddPharmacy()].includes(pathname)) {
      return <Navigate to={getRouteNoAccess()} />;
    }
  }

  return <Component {...props} />;
}
