import { Suspense, memo } from 'react';
import { Routes, Route } from 'react-router-dom';

import { routes } from '../../../../Routes/AllRoutes';
import GeneralLayout from '../../../../Shared/layouts/GeneralLayout';
import LoadingScreen from '../../../../Shared/LoadingScreen';
import { PrivateRoute } from '../../../../Routes/PrivateRoutes';
import { PublicRoute } from '../../../../Routes/PublicRoutes';

export const AppRouter = memo(() => {
  return (
    <Routes>
      {routes.map((route, index) => {
        return (
          <Route
            key={index}
            path={route.path}
            element={(
              <GeneralLayout
                isPublic={route.isPublic}
                isGuest={route.isGuest}
                isPrivate={route.isPrivate}
                isAuth={route.isAuth}
                theme={route.theme ?? 'black'}
                withoutLayout={route.withoutLayout}
              >
                <Suspense fallback={<LoadingScreen />}>
                  {!route.isPublic ? (
                    <PrivateRoute
                      props={route}
                      Component={route.component}
                    />
                  ) : (
                    <PublicRoute
                      props={route}
                      Component={route.component}
                    />
                  )}
                </Suspense>
              </GeneralLayout>
            )}
          />
        );
      })}
    </Routes>
  );
});
