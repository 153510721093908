import { lazy } from 'react';

import { adminRole } from './routeConfig';
import { ManageNdcAsync } from '../Pages/Admin/ManageNdc';
import { AppRouteProps } from '../app/providers/Router/model/types';

export const adminRoutes: AppRouteProps[] = [
  {
    path: '/admin/profile',
    component: lazy(() => import('../Pages/Admin/Profile')),
    isPublic: false,
    role: [adminRole],
  },
  {
    path: '/admin/changePassword',
    component: lazy(() => import('../Pages/Admin/ChangePassword')),
    isPublic: false,
    role: [adminRole],
  },
  {
    path: 'admin/userProfile/:userId',
    component: lazy(() => import('../Pages/Admin/User/UserProfile')),
    isPublic: false,
    role: [adminRole],
  },
  {
    path: '/admin/commission',
    component: lazy(() => import('../Pages/Admin/Commission')),
    isPublic: false,
    role: [adminRole],
  },
  {
    path: '/admin/commissionRecord',
    component: lazy(() => import('../Pages/Admin/CommissionRecord')),
    isPublic: false,
    role: [adminRole],
  },
  {
    path: '/admin/buyer/wishlist',
    component: lazy(() => import('../Pages/Admin/Buyer/Wishlist')),
    isPublic: false,
    role: [adminRole],
  },
  {
    path: '/admin/seller/wishlist',
    component: lazy(() => import('../Pages/Admin/Seller/Wishlist')),
    isPublic: false,
    role: [adminRole],
  },
  {
    path: '/admin/manage/users',
    component: lazy(() => import('../Pages/Admin/User/UserManagement')),
    isPublic: false,
    role: [adminRole],
  },
  {
    path: '/admin/matchingWishlist/:wishlistId',
    component: lazy(() => import('../Pages/Admin/MatchingWishlist')),
    isPublic: false,
    role: [adminRole],
  },
  {
    path: '/adminView/userProfile/:userId',
    component: lazy(() => import('../Pages/Admin/User/UserProfile')),
    isPublic: false,
    role: [adminRole],
  },
  {
    path: '/admin/order',
    component: lazy(() => import('../Pages/Admin/Order')),
    isPublic: false,
    role: [adminRole],
  },
  {
    path: '/admin/manage/ndc',
    component: ManageNdcAsync,
    isPublic: false,
    role: [adminRole],
  },
];
