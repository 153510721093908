import { Container } from 'reactstrap';
import { useSelector } from 'react-redux';
import React, { useCallback, useState } from 'react';

import Topbar from '../../Topbar';
import functions from '../../../utils/functions';
import { UserSidebar } from './UserSidebar';
import { AdminSidebar } from './AdminSidebar';

const GeneralLayout = (props) => {
  const { user } = useSelector((state) => state.user);
  const [toggleSidebar, setToggleSidebar] = useState(true);

  const setToogle = useCallback((value) => {
    setToggleSidebar(value);
  }, []);

  if (props.withoutLayout) {
    return (
      <Container fluid>{props.children}</Container>
    );
  }

  return (
    <>
      {user && (
        <>
          {functions.isAdmin(user?.role) && (
            <AdminSidebar
              toggleSidebar={toggleSidebar}
              setToggleSidebar={setToogle}
            />
          )}
          {functions.isUser(user?.role) && (
            <UserSidebar
              toggleSidebar={toggleSidebar}
              setToggleSidebar={setToogle}
            />
          )}
          <div
            className={`transition vh-100 overflow-y-scroll ${
              toggleSidebar && window.innerWidth > 576 ? 'openSidebar' : ''
            }`}
          >
            <div>
              <Topbar
                toggleSidebar={toggleSidebar}
                setToggleSidebar={setToogle}
              />
            </div>

            <Container fluid>{props.children}</Container>
          </div>
        </>
      )}
      {user === null && <Container fluid>{props.children}</Container>}
    </>
  );
};

export default GeneralLayout;
