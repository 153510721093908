import { Button } from 'reactstrap';
import { SlLogout } from 'react-icons/sl';
import { RxCross2 } from 'react-icons/rx';
import { useDispatch } from 'react-redux';
import { FaNotesMedical } from 'react-icons/fa';
import { IoPerson } from 'react-icons/io5';
import { FaCartPlus } from 'react-icons/fa6';
import React, { memo, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu, MenuItem, SubMenu } from 'react-pro-sidebar';

import Images from '../../../Helper/ImageConstants';
import Toaster from '../../Toaster';
import { customLogout } from '../../../Redux/features/User/userSlice';

export const AdminSidebar = memo(({ toggleSidebar, setToggleSidebar }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const handleLogoutClick = useCallback(() => {
    dispatch(customLogout());
    Toaster.success('You have successfully logged out');
  }, [dispatch]);

  return (
    <div
      className="sidebar-content shadow-lg transition position-absolute"
      style={{
        width: toggleSidebar ? '260px' : '0px',
        overflow: 'hidden',
      }}
    >
      <div className="d-flex py-3 justify-content-center ">
        <div className="d-flex align-items-center justify-content-between gap-2 mb-4">
          <img src={Images.LOGO_IMG} alt="Parry Script" width="30px" />
          <h5>PARRY SCRIPT</h5>
        </div>
        <div>
          {window.innerWidth <= 576 && (
            <Button
              onClick={() => setToggleSidebar(!toggleSidebar)}
              className="border-0 cursor-pionter text-align-center bg-transparent"
            >
              <RxCross2 className="text-danger" />
            </Button>
          )}
        </div>
      </div>

      <Menu className="Menu fw-bolder overflow-hidden">
        <SubMenu
          icon={<FaCartPlus size={18} />}
          label="Wishlist"
          className="subMenu text-white"
        >
          <Link
            to="/admin/buyer/wishlist"
            className="decorationNone text-white"
          >
            <MenuItem
              className={`${
                pathname.includes('/buyer/wishlist')
                  ? 'activeSubMenuItem'
                  : 'subMenuItem'
              }`}
            >
              Buyer Wishlist
            </MenuItem>
          </Link>

          <Link
            to="/admin/seller/wishlist"
            className="decorationNone text-white"
          >
            <MenuItem
              className={`${
                pathname.includes('/seller/wishlist')
                  ? 'activeSubMenuItem'
                  : 'subMenuItem'
              }`}
            >
              Seller Wishlist
            </MenuItem>
          </Link>
        </SubMenu>
        <SubMenu
          icon={<FaCartPlus size={18} />}
          label="Commission"
          className="subMenu text-white"
        >
          <Link
            className={`${
              /^\/admin\/commission\/?$/.test(pathname)
                ? 'activeMenuItem'
                : 'MenuItem'
            }  text-light`}
            to="/admin/commission"
            style={{ textDecoration: 'none' }}
          >
            <MenuItem icon={<IoPerson size={18} />}>
              Commission Value
            </MenuItem>
          </Link>
          <Link
            className={`${
              pathname.includes('/admin/commissionRecord')
                ? 'activeMenuItem'
                : 'MenuItem'
            }  text-light`}
            to="/admin/commissionRecord"
            style={{ textDecoration: 'none' }}
          >
            <MenuItem icon={<IoPerson size={18} />}>
              Commission Record
            </MenuItem>
          </Link>
        </SubMenu>
        <Link
          className={`${
            pathname.includes('/admin/manage/users')
              ? 'activeMenuItem'
              : 'MenuItem'
          }  text-light`}
          to="/admin/manage/users"
          style={{ textDecoration: 'none' }}
        >
          <MenuItem icon={<IoPerson size={18} />}>Manage Users</MenuItem>
        </Link>
        <Link
          className={`${
            pathname.includes('/admin/manage/ndc')
              ? 'activeMenuItem'
              : 'MenuItem'
          }  text-light`}
          to="/admin/manage/ndc"
          style={{ textDecoration: 'none' }}
        >
          <MenuItem icon={<FaNotesMedical size={18} />}>Manage NDC</MenuItem>
        </Link>
        <Link
          className={`${
            pathname.includes('/admin/order') ? 'activeMenuItem' : 'MenuItem'
          }  text-light`}
          to="/admin/order"
          style={{ textDecoration: 'none' }}
        >
          <MenuItem icon={<IoPerson size={18} />}>Orders</MenuItem>
        </Link>
        <Link
          className={`${
            pathname.includes('/admin/profile') ? 'activeMenuItem' : 'MenuItem'
          }  text-light`}
          to="/admin/profile"
          style={{ textDecoration: 'none' }}
        >
          <MenuItem icon={<IoPerson size={18} />}>Profile</MenuItem>
        </Link>
        <MenuItem
          className="MenuItem position-absolute bottom-0 w-100 text-light"
          icon={<SlLogout size={18} />}
          onClick={handleLogoutClick}
        >
          Log Out
        </MenuItem>
      </Menu>
    </div>
  );
});
