import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import functions from '../utils/functions';

export function PublicRoute({ Component, props }) {
  const { user } = useSelector((state) => state.user);

  if (user) {
    return (
      <Navigate
        to={functions.getInitialDashboardRoute(user?.role)}
      />
    );
  }

  return <Component {...props} />;
}
