import { lazy } from 'react';

import { SellerWishlistAsync } from '../Pages/User/SellerWishlist';
import { userRole } from './routeConfig';
import { AppRouteProps } from '../app/providers/Router/model/types';
import { getRouteUserChangePassword } from '../Shared/const/router';

export const userRoutes: AppRouteProps[] = [
  {
    path: '/user/profile',
    component: lazy(() => import('../Pages/User/Profile')),
    isPublic: false,
    role: [userRole],
  },
  {
    path: getRouteUserChangePassword(),
    component: lazy(() => import('../Pages/User/ChangePassword')),
    isPublic: false,
    role: [userRole],
  },
  {
    path: '/user/buyer/wishlist',
    component: lazy(() => import('../Pages/User/Buyer/Wishlist')),
    isPublic: false,
    role: [userRole],
  },
  {
    path: '/user/seller/wishlist',
    component: SellerWishlistAsync,
    isPublic: false,
    role: [userRole],
  },
  {
    path: '/user/buyer/potentialBuys/:buyerWishlistId',
    component: lazy(() => import('../Pages/User/Buyer/PotentialBuy')),
    isPublic: false,
    role: [userRole],
  },
  {
    path: '/user/seller/incomingSale',
    component: lazy(() => import('../Pages/User/Seller/IncomingSale')),
    isPublic: false,
    role: [userRole],
  },
  {
    path: '/user/seller/order',
    component: lazy(() => import('../Pages/User/Seller/Order')),
    isPublic: false,
    role: [userRole],
  },
  {
    path: '/user/buyer/order',
    component: lazy(() => import('../Pages/User/Buyer/Order')),
    isPublic: false,
    role: [userRole],
  },
];
