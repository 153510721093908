import { lazy } from 'react';

import { SignUpAsync } from '../Pages/Auth/SignUp';
import { AddPharmacyAsync } from '../Pages/User/AddPharmacy';
import { userRoutes } from './UserRoutes';
import { adminRoutes } from './AdminRoutes';
import { userRole, adminRole } from './routeConfig';
import { AppRouteProps } from '../app/providers/Router/model/types';
import { getRouteAddPharmacy, getRouteLogin, getRouteNoAccess } from '../Shared/const/router';
import { NoAccessAsync } from '../Pages/User/NoAccess';

export const routes: AppRouteProps[] = [
  {
    path: '/',
    component: lazy(() => import('../Pages/Auth/LandingPage')),
    isPublic: true,
    role: [userRole, adminRole],
  },
  {
    path: getRouteLogin(),
    component: lazy(() => import('../Pages/Auth/SignIn')),
    isPublic: true,
    role: [userRole, adminRole],
  },
  {
    path: '/signUp',
    component: SignUpAsync,
    isPublic: true,
    role: [userRole, adminRole],
  },
  {
    path: '/otpVerification/:type',
    component: lazy(() => import('../Pages/Auth/OtpVerification')),
    isPublic: true,
    role: [userRole, adminRole],
  },
  {
    path: getRouteAddPharmacy(),
    component: AddPharmacyAsync,
    isPublic: false,
    role: [userRole, adminRole],
    withoutLayout: true,
  },
  {
    path: '/forgotPassword',
    component: lazy(() => import('../Pages/Auth/ForgotPassword')),
    isPublic: true,
    role: [userRole, adminRole],
  },
  {
    path: '/resetPassword',
    component: lazy(() => import('../Pages/Auth/ResetPassword')),
    isPublic: true,
    role: [userRole, adminRole],
  },
  {
    path: getRouteNoAccess(),
    component: NoAccessAsync,
    isPublic: false,
    role: [userRole, adminRole],
  },
  ...userRoutes,
  ...adminRoutes,
];
