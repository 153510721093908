import './app.scss';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';

import { AppRouter } from './providers/Router';
import { getNotificationToken, onMessageListener } from './firebase';
import {
  GET_USER_NOTIFICATIONS_URL,
} from '../utils/constants.js';
import { setFcmToken } from '../Redux/features/User/userSlice';
import { getUserNotifications, getUserProfile } from '../Redux/features/User/userApi';
import NotificationToaster from '../Shared/NotificationToaster';

import 'react-date-range/dist/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-date-range/dist/theme/default.css';

function App() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);

  const [notification, setNotification] = useState(null);
  const [showNotification, setShowNotification] = useState(false);

  const fetchUserNotifications = () => {
    setTimeout(() => {
      if (user) {
        const data = {
          apiEndpoint: GET_USER_NOTIFICATIONS_URL,
        };

        dispatch(getUserNotifications(data));
      }
    }, 500);
  };

  const initData = async () => {
    if (user) {
      dispatch(getUserProfile());
    }
  };

  const setToken = async () => {
    const firebaseToken = await getNotificationToken();
    if (firebaseToken) {
      dispatch(setFcmToken(firebaseToken));
    }
  };

  useEffect(() => {
    setToken();
    initData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  onMessageListener()
    .then((payload) => {
      const { body } = payload.notification;
      const { title } = payload.notification;
      setNotification({
        title,
        body,
      });
      fetchUserNotifications();
      setShowNotification(true);
      setTimeout(() => {
        setShowNotification(false);
      }, 5000);
    })
    .catch((err) => console.log('failed: ', err));

  return (
    <>
      <ToastContainer />
      <NotificationToaster
        showNotification={showNotification}
        setShowNotification={setShowNotification}
        notificationData={notification}
      />
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
    </>
  );
}

export default App;
