import { createAsyncThunk } from '@reduxjs/toolkit';

import axiosInstance from '../../interceptor';
import Toaster from '../../../Shared/Toaster';

export const getAllUsers = createAsyncThunk(
  'getAllUsers',
  async ({ apiEndpoint }, thunkAPI) => {
    try {
      const response = await axiosInstance.get(apiEndpoint);
      return response?.data?.data;
    } catch (error) {
      Toaster.error(error?.response?.data?.message);
      return thunkAPI.rejectWithValue({ statusCode: error.response.status });
    }
  },
);

export const getNdcList = createAsyncThunk(
  'getNdcList',
  async (page, thunkAPI) => {
    try {
      const response = await axiosInstance.get(`/ndc?page=${page}`);
      return response?.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ statusCode: error.response.status });
    }
  },
);

export const approveUser = createAsyncThunk(
  'approveUser',
  async (userId, thunkAPI) => {
    try {
      const response = await axiosInstance.patch(`/user/${userId}/approve`);
      Toaster.success(response?.data?.message);
      return response.data;
    } catch (error) {
      Toaster.error(error?.response?.data?.message);
      return thunkAPI.rejectWithValue({ statusCode: error.response.status });
    }
  },
);

export const rejectUser = createAsyncThunk(
  'rejectUser',
  async (userId, thunkAPI) => {
    try {
      const response = await axiosInstance.patch(`/user/${userId}/reject`);
      Toaster.success(response?.data?.message);
      return response.data;
    } catch (error) {
      Toaster.error(error?.response?.data?.message);
      return thunkAPI.rejectWithValue({ statusCode: error.response.status });
    }
  },
);

export const changeUserCommissionValue = createAsyncThunk(
  'changeUserCommissionValue',
  async ({ apiEndpoint, requestData }, thunkAPI) => {
    try {
      const response = await axiosInstance.put(apiEndpoint, requestData);
      Toaster.success(response?.data?.message);
      return response.data;
    } catch (error) {
      Toaster.error(error?.response?.data?.message);
      return thunkAPI.rejectWithValue({ statusCode: error.response.status });
    }
  },
);

export const sendMatchedWishlistToBuyer = createAsyncThunk(
  'sendMatchedWishlistToBuyer',
  async ({ apiEndpoint, requestData }, thunkAPI) => {
    try {
      const response = await axiosInstance.post(apiEndpoint, requestData);
      Toaster.success(response?.data?.message);
      return response.data;
    } catch (error) {
      Toaster.error(error?.response?.data?.message);
      return thunkAPI.rejectWithValue({ statusCode: error.response.status });
    }
  },
);

export const getWishlistMatching = createAsyncThunk(
  'getWishlistMatching',
  async ({ apiEndpoint, requestData }, thunkAPI) => {
    try {
      const response = await axiosInstance.get(apiEndpoint, requestData);
      return response.data;
    } catch (error) {
      Toaster.error(error?.response?.data?.message);
      return thunkAPI.rejectWithValue({ statusCode: error.response.status });
    }
  },
);

export const getAdminUserProfile = createAsyncThunk(
  'getAdminUserProfile',
  async ({ apiEndpoint, requestData }, thunkAPI) => {
    try {
      const response = await axiosInstance.get(apiEndpoint, requestData);
      return response.data;
    } catch (error) {
      Toaster.error(error?.response?.data?.message);
      return thunkAPI.rejectWithValue({ statusCode: error.response.status });
    }
  },
);

export const getCommission = createAsyncThunk(
  'getCommission',
  async ({ apiEndpoint }, thunkAPI) => {
    try {
      const response = await axiosInstance.get(apiEndpoint);
      return response.data;
    } catch (error) {
      Toaster.error(error?.response?.data?.message);
      return thunkAPI.rejectWithValue({ statusCode: error.response.status });
    }
  },
);

export const getAllOrders = createAsyncThunk(
  'getAllOrders',
  async ({ apiEndpoint }, thunkAPI) => {
    try {
      const response = await axiosInstance.get(apiEndpoint);
      return response?.data?.data;
    } catch (error) {
      Toaster.error(error?.response?.data?.message);
      return thunkAPI.rejectWithValue({ statusCode: error.response.status });
    }
  },
);

export const getCommissionRecords = createAsyncThunk(
  'getCommissionRecords',
  async ({ apiEndpoint, requestData }, thunkAPI) => {
    try {
      const response = await axiosInstance.post(apiEndpoint, requestData);
      return response?.data?.data;
    } catch (error) {
      Toaster.error(error?.response?.data?.message);
      return thunkAPI.rejectWithValue({ statusCode: error.response.status });
    }
  },
);

export const updateCommissionValue = createAsyncThunk(
  'updateCommissionValue',
  async ({ apiEndpoint, requestData }, thunkAPI) => {
    try {
      const response = await axiosInstance.put(apiEndpoint, requestData);
      Toaster.success(response?.data?.message);
      return response?.data?.data;
    } catch (error) {
      Toaster.error(error?.response?.data?.error?.detail);
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const importNdc = createAsyncThunk(
  'importNdc',
  async (data, thunkAPI) => {
    try {
      const response = await axiosInstance.post('/ndc/uploadNdcData', data);
      Toaster.success(response?.data?.message);
      return response?.data?.data;
    } catch (error) {
      Toaster.error(error?.response?.data?.message);
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);
